import React from "react"
import Layout from '../components/Layout'
import {Location} from '@reach/router'
import Content from '../components/Content'
export default () => (
    <Location>
        {
            props=>(
                <Layout location={props.location}>
                    <Content>
                        Page Not Found!
                    </Content>
                </Layout>
            )
        }
    </Location>
)
